import { useMemo } from 'react';

import { PageSectionTypes } from '~/app/components/ItemPage/sections/types';
import { SelectedCustomPage } from '~/app/lib/store/types';

import {
  ItemContext,
  ItemPageLayoutDefinition,
} from '~/app/components/ItemPage/types';

const DEFAULT_LAYOUT: ItemPageLayoutDefinition['main'] = [
  {
    component: PageSectionTypes.PAGE_TITLE,
  },
];

const useCustomPageContext = ({
  customPage,
  originalItemContext,
}: {
  customPage: SelectedCustomPage;
  originalItemContext?: ItemContext;
}) => {
  const config = customPage?.config;
  const addonsResolved = config?.addons || {};

  return useMemo<ItemContext>(
    () => ({
      presets: {},

      data: {
        item: customPage,
        custom: config?.objects,
      },

      layout: {
        main: toLayout(customPage),
      },

      config,

      addons: addonsResolved,
      originalItemContext,
    }),
    [customPage, originalItemContext]
  );
};

const toLayout = (customPage: SelectedCustomPage) => {
  if (customPage.config?.layout && customPage.config?.version !== 2) {
    return toLayoutFromLegacyFormat(customPage);
  }

  // use custom layout if defined, otherwise use the default layout
  return customPage.config?.layout?.main || DEFAULT_LAYOUT;
};

const toLayoutFromLegacyFormat = (customPage: SelectedCustomPage) => {
  const customLayout = customPage.config?.layout?.main;

  // if no custom layout is provided, use the default layout
  if (!customLayout?.length) {
    return DEFAULT_LAYOUT;
  }

  // prepend the title to the custom layout
  return [...DEFAULT_LAYOUT.slice(0, 1), ...customLayout];
};

export default useCustomPageContext;
